import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import * as Widget from './Widget';
import Link from './Link';

class Banner extends React.Component {
  render() {
    let { home } = this.props;

    return (
      <Carousel
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        autoplay={true}
        autoplayInterval={3000}
        wrapAround={true}
      >
        <CarouselItem link={home.carousel1_link}>
          <Widget.BgImage image={home.carousel1_image} />
        </CarouselItem>
        {home.carousel2_image && (
          <CarouselItem link={home.carousel2_link}>
            <Widget.BgImage image={home.carousel2_image} />
          </CarouselItem>
        )}
        {home.carousel3_image && (
          <CarouselItem link={home.carousel3_link}>
            <Widget.BgImage image={home.carousel3_image} />
          </CarouselItem>
        )}
      </Carousel>
    );
  }
}

class CarouselItem extends React.Component {
  static Wrapper = styled.div`
    display: block;
    width: 100%;
    height: 600px;

    @media screen and (max-width: 600px) {
      height: 309px;
    }

    ${props => props.extraCss};
  `;

  render() {
    let { children, extraCss, link } = this.props;

    return (
      <CarouselItem.Wrapper extraCss={extraCss}>
        {link ? <Link to={link}>{children}</Link> : children}
      </CarouselItem.Wrapper>
    );
  }
}

export default Banner;
